import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorker.register();

// Registro do Service Worker
// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("./service-worker.js")
//       .then((registration) => {
//         console.log("Service Worker registrado com sucesso:", registration);
//       })
//       .catch((error) => {
//         console.log("Falha ao registrar o Service Worker:", error);
//       });
//   });
// }

// Se você quiser medir o desempenho do seu aplicativo, pode usar a função reportWebVitals
reportWebVitals();
