import React from 'react';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { router } from './routes';
import { ChakraProvider } from '@chakra-ui/react';
import { AppProvider } from './contexts';
import { NetworkProvider } from './contexts/Network';
function App() {
  return (
    <NetworkProvider>
      <ChakraProvider>
        {/* <BrowserRouter> */}
        {/* <AppProvider> */}
        <RouterProvider router={router} />
        {/* <Router /> */}
        {/* </AppProvider> */}
        {/* </BrowserRouter> */}
      </ChakraProvider>
    </NetworkProvider>
  );
}

export default App;
