import api from '../services/api';
import { createContext } from 'react';

export const BudgetContext = createContext({});

// refatorar todas essas chaamdas pra adicionar todos esses results em stores??

export const BudgetProvider = ({ children }) => {
  const createBudgetApi = async (data) => {
    const response = await api.post('create/budget', data);
    return response;
  };

  const listBudgetsApi = async () => {
    const response = await api.get('list/budgets');
    return response;
  };

  const sendBudgetApi = async (id) => {
    const response = await api.post(`send/budget/${id}`);
    return response;
  };

  const sendBudgetsApi = async () => {
    const response = await api.post(`send/budgets`);

    return response;
  };

  return (
    <BudgetContext.Provider
      value={{ createBudgetApi, listBudgetsApi, sendBudgetApi, sendBudgetsApi }}
    >
      {children}
    </BudgetContext.Provider>
  );
};
