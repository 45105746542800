import {
  Alert,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  IconButton,
  Stack,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { DeleteIcon, EmailIcon, RepeatIcon } from '@chakra-ui/icons';
import { AlertIcon } from '@chakra-ui/react';
import { useState, useEffect, useContext } from 'react';
import Drawer from '../../components/Drawer';
import Header from '../../components/Header';
import { BudgetContext } from '../../contexts/Budgets';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NetworkContext } from '../../contexts/Network';

import { MdOutlineSyncDisabled } from 'react-icons/md';

export default function Budgets() {
  const { listBudgetsApi, sendBudgetApi, sendBudgetsApi } =
    useContext(BudgetContext);
  const { budgetsOffline, isOnline, syncWithServer } =
    useContext(NetworkContext);

  const [rows, setRows] = useState([]);

  const mergeBudgetsOffline = (budgets) => {
    if (budgetsOffline?.length > 0) {
      const budgetsAddedSync = budgetsOffline?.map((budget) => ({
        ...budget,
        id: '-',
        noSync: true,
        full_name: budget.name,
        product_name: budget.product_name ?? ' Não sincronizado',
        category_name: budget.category_name ?? 'Não sincronizado',
      }));

      return [...budgetsAddedSync, ...budgets];
    }

    return budgets;
  };

  async function listBudgets() {
    try {
      const { data } = await listBudgetsApi();
      const budgetsOffline = mergeBudgetsOffline(data[0]);
      setRows([...budgetsOffline, ...data[0]]);
      //    console.log(categoriasApi.data[0]);
    } catch (error) {
      console.log(error);
      setRows(mergeBudgetsOffline([]));
    }
  }

  useEffect(() => {
    listBudgets();
  }, [budgetsOffline]);
  function handleDelete(event, id) {
    event.preventDefault();
    // função para deletar um item pelo id
  }

  async function handleSendUnique(event, id) {
    event.preventDefault();
    try {
      const budgetsApi = await sendBudgetApi(id);
      // Exibir mensagem de sucesso
      toast.success('Orçamento enviado com sucesso!');
      // Limpar todos os campos do formulário
      listBudgets();
    } catch (error) {
      console.log(error);
      toast.error(
        'Ocorreu um erro ao enviar o orçamento. Por favor, tente novamente.'
      );
    }
  }

  async function handleSendOrcamentos() {
    try {
      const budgetsApi = await sendBudgetsApi();
      // Exibir mensagem de sucesso
      toast.success('Orçamentos enviados com sucesso!');
      // Limpar todos os campos do formulário
      listBudgets();
    } catch (error) {
      console.log(error);
      toast.error(
        'Ocorreu um erro ao enviar os orçamentos. Por favor, tente novamente.'
      );
    }
  }

  return (
    <>
      <Drawer />
      <Header />
      <Flex>
        <Box w="100%">
          <Container
            minWidth={'75vw'}
            marginTop={{ base: '72px', md: '120px' }}
            ml={{ base: '100px', md: '150px' }}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="xl">Orçamentos</Heading>
              <Button
                leftIcon={<EmailIcon />}
                colorScheme="red"
                size="md"
                onClick={handleSendOrcamentos}
              >
                Enviar Todos
              </Button>
            </Flex>

            {budgetsOffline?.length > 0 && (
              <Alert
                mt="45px"
                status="warning"
                justifyContent={'space-between'}
              >
                <Flex>
                  <AlertIcon />
                  <Text as="b">
                    Existem orçamentos que não foram sincronizados com o
                    servidor.
                  </Text>
                </Flex>
                <Button
                  rightIcon={<RepeatIcon />}
                  colorScheme="red"
                  variant={'outline'}
                  onClick={syncWithServer}
                >
                  Sincronizar
                </Button>
              </Alert>
            )}

            <Table variant="striped" colorScheme="gray" mt={'45px'}>
              <TableCaption>Lista de orçamentos</TableCaption>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Cliente</Th>
                  <Th>Produto</Th>
                  <Th>Categoria</Th>
                  <Th>Status</Th>
                  <Th></Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {rows.length > 0 &&
                  rows.map((row, index) => (
                    <Tr key={`${row.id}-${index}`}>
                      <Td>{row.id}</Td>
                      <Td>
                        <Flex alignItems="center" gap={2}>
                          <Text color={row.noSync ? 'orange.300' : 'black'}>
                            {row.full_name}
                          </Text>
                          {row.noSync && (
                            <MdOutlineSyncDisabled
                              style={{ color: '#F6AD55' }}
                              size={24}
                            />
                          )}
                        </Flex>
                      </Td>
                      <Td>{row.product_name}</Td>
                      <Td>{row.category_name}</Td>
                      <Td>
                        <Text
                          color={
                            row.status == 'sended' ? 'green.500' : 'red.500'
                          }
                        >
                          {row.noSync
                            ? 'Não sincronizado'
                            : row.status == 'sended'
                            ? 'Enviado'
                            : 'Pendente'}
                        </Text>
                      </Td>
                      <Td>
                        {!row.status && !row.noSync && (
                          <IconButton
                            colorScheme="red"
                            aria-label="Delete"
                            icon={<DeleteIcon />}
                            onClick={(e) => handleDelete(e, row.id)}
                          />
                        )}
                      </Td>
                      <Td>
                        {row.status == 'pending' && (
                          <IconButton
                            colorScheme="green"
                            aria-label="Send"
                            icon={<EmailIcon />}
                            onClick={(e) => handleSendUnique(e, row.id)}
                          />
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Container>
        </Box>
      </Flex>
      <ToastContainer />
    </>
  );
}
