import api from '../services/api';
import { createContext } from 'react';

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const createUserApi = async (data) => {
    const response = await api.post('create/user', data);

    return response;
  };

  const listUsersApi = async () => {
    const response = await api.get('list/users');

    return response;
  };

  const activateUserApi = async (id) => {
    const response = await api.put(`activate/user/${id}`);

    return response;
  };

  const inactivateUserApi = async (id) => {
    const response = await api.put(`inactivate/user/${id}`);

    return response;
  };

  return (
    <UserContext.Provider
      value={{
        createUserApi,
        listUsersApi,
        activateUserApi,
        inactivateUserApi,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
