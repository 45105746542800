import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Link,
    Text,
    VStack,
} from "@chakra-ui/react";
import React, { useState, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import LogoImg from "../../images/logo_esco.png";
import { AuthContext } from "../../contexts/Auth";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ResetPassword() {


    const navigate = useNavigate();
    const { resetPasswordApi } = useContext(AuthContext);
    const [searchParams] = useSearchParams();
    const emailParam = searchParams.get('email');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const handleSubmit = async (event) => {
        event.preventDefault();
      
        if (password === repeatPassword) {

            const data = {
                email: emailParam,
                password: password
            };

            console.log(data);
            try {
                const response = await resetPasswordApi(data);

                toast.info('Senha alterada', {
                    autoClose: 5000,
                });
                setTimeout(() => {
                    navigate("/");
                }, 3000);
              
            } catch (error) {
                console.log(error);
                toast.info("Erro ao se conectar com o servidor!", {
                    autoClose: 5000,

                    
                });
            }
        } else {
            // As senhas não coincidem, exibir mensagem de erro
            setPasswordsMatch(false);
        }
    };

    return (

        <>
        
        <Box
            minH="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="#DB1015"
        >
            <VStack spacing={8}  width={'300px'}>
                <Box alignSelf="center" mt={"35px"}>
                    <Link as={RouterLink} to="/">
                        <img src={LogoImg} alt="Esco" width={250} />
                    </Link>
                </Box>
                <Box bgColor="#FFF" borderRadius="md" p={8}>
                    <Text fontSize="2xl" fontWeight="bold" mb={8}>
                        Recuperar Senha
                    </Text>
                    <form onSubmit={handleSubmit}>
                        <FormControl id="password" mt={4} isRequired>
                            <FormLabel>Nova senha</FormLabel>
                            <Input
                                type="password"
                                placeholder="Digite sua nova senha"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </FormControl>
                        <FormControl id="repeatPassword" mt={4} isRequired>
                            <FormLabel>Repita a nova senha</FormLabel>
                            <Input
                                type="password"
                                placeholder="Digite sua nova senha novamente"
                                value={repeatPassword}
                                onChange={(event) => setRepeatPassword(event.target.value)}
                            />
                        </FormControl>
                        {/*!passwordsMatch && (
                            <Alert status="error" mt={4}>
                                <AlertIcon />
                                As senhas não coincidem. Por favor, tente novamente.
                            </Alert>
                        )*/}
                        <Button
                            type="submit"
                            bgColor="#BC080D"
                            color="#FFF"
                            mt={8}
                            _hover={{ bgColor: "#9e080c" }}
                        >
                            ENVIAR
                        </Button>
                    </form>
                </Box>
                <Text alignSelf="center" mt={8} textDecoration="underline" color={'white'} fontSize={'14px'}>
                    <Link as={RouterLink} to="/">
                        Voltar para a página de login
                    </Link>
                </Text>
            </VStack>
        </Box>

        <ToastContainer />
        </>
    );
}

export default ResetPassword;
