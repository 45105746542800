import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState, useContext } from "react";
import Drawer from "../../components/Drawer";
import Header from "../../components/Header";
import { ProductContext } from "../../contexts/Products";
import { Link, useParams } from "react-router-dom";

const Search = () => {
  const url = "https://esco.cognilabs.com.br/public/uploads/";

  const { search } = useParams();
  const [showFilter, setShowFilter] = useState(false);

  const { listProductsSearch } = useContext(ProductContext);

  const [products, setProducts] = useState();

  const toggleFilter = () => setShowFilter(!showFilter);

  async function listProducts() {
    try {
      const productsApi = await listProductsSearch(search);
      setProducts(productsApi.data[0]);
      // console.log(productsApi.data[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (search) {
      listProducts();
    }
  }, [search]);

  return (
    <>
      <Flex>
        <Drawer />
        <Header />
        <Container maxW="container.lg" mt="90">
          <Heading as="h3" fontSize="xl" textTransform="uppercase">
            {" "}
            Você pesquisou por:
          </Heading>

          {products?.length === 0 ? (
            <>
              <Flex alignItems="start">
                <VStack marginTop={"15px"}>
                  <Box alignSelf={"flex-start"}>
                    <Heading as="h4" fontSize="xl" textTransform="uppercase">
                      {search}
                    </Heading>
                  </Box>
                  <Box>
                    <Heading as="h4" fontSize="md" textTransform="uppercase">
                      Não foram encontrados produtos, tente outro nome
                    </Heading>
                  </Box>
                </VStack>
              </Flex>
            </>
          ) : (
            <Flex justify="space-between" align="center">
              <VStack marginTop={"15px"}>
                <Heading as="h4" fontSize="xl" textTransform="uppercase">
                  {search}
                </Heading>
              </VStack>
            </Flex>
          )}

          <Box mt={8}>
            <SimpleGrid
              columns={{ base: 1, md: 1 }}
              spacing={8}
              zIndex={2}
              width={"450px"}
            >
              {products?.map((produto) => (
                <Link to={`/produto/${produto.id}`}>
                  <Box
                    display={"inline-flex"}
                    mb={5}
                    key={produto.id}
                    cursor="pointer"
                  >
                    <Image
                      src={url + produto.principal_image}
                      alt={produto.title}
                      width={"190px"}
                      h={"150px"}
                      mt={5}
                    />

                    <Box p="4" maxH={"90px"}>
                      <Text
                        fontSize="14px"
                        fontWeight="bold"
                        color="brand.gray"
                        mb="2"
                      >
                        {produto.title}
                      </Text>
                      <Text fontSize="12px" mb="2">
                        {produto.subtitle}
                      </Text>
                    </Box>
                  </Box>
                </Link>
              ))}
            </SimpleGrid>
          </Box>
        </Container>
        {products?.map((product) => (
          <Box
            position="fixed"
            top="72px"
            right="0"
            w={{ base: "full", md: "50%" }}
            h="calc(100vh - 72px)"
            borderLeft="1px solid"
            borderColor="brand.gray"
            bg="white"
            p="6"
            mb={5}
            overflowY="auto"
          >
            <Container maxW="container.md">
              <Image
                src={url + product.principal_image}
                alt={product.title}
                mb="6"
              />
              <Heading
                as="h2"
                fontSize="lg"
                fontWeight="bold"
                color="brand.darkGray"
                mb="6"
              >
                {product.title}
              </Heading>
              <Text fontSize="md" color="brand.gray" mb="6">
                <strong>{product.subtitle}</strong>
              </Text>
              <Text fontSize="md" color="brand.gray" mb="6">
                {product.description}
              </Text>
              <Link
                to={`/produto/${product.id}`}
                display="block"
                bg={"#BC080D"}
                width={"270px"}
                color="white"
                fontWeight="bold"
                p="4"
                borderRadius="sm"
                _hover={{ bg: "brand.darkRed" }}
              >
                <Box
                  bg={"#BC080D"}
                  width={"190px"}
                  color="white"
                  fontWeight="bold"
                  fontSize={"12px"}
                  p="4"
                  borderRadius="sm"
                  _hover={{ bg: "brand.darkRed" }}
                >
                  <Text>Ver detalhes do produto</Text>
                </Box>
              </Link>
            </Container>
          </Box>
        ))}
      </Flex>
    </>
  );
};

export default Search;
