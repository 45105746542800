import React, { useContext } from 'react';

import { AuthProvider } from './Auth';
import { ProductProvider } from './Products';
import { BudgetProvider } from './Budgets';
import { UserProvider } from './User';
export const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <ProductProvider>
        <BudgetProvider>
          <UserProvider>{children}</UserProvider>
        </BudgetProvider>
      </ProductProvider>
    </AuthProvider>
  );
};
