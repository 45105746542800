
import { Flex, Text, Box} from '@chakra-ui/react';
import Drawer from '../../components/Drawer';
import Header from '../../components/Header';
import BudgetForm from '../../components/BudgetForm';

function RegisterBudget() {
    return (
        <Flex>
            <Drawer />
            <Header />
            <Flex
                minWidth={'100%'}
                marginTop={{ base: '72px', md: '50px' }}
                ml={{ base: '100px', md: '150px' }}

                flexDir={'column'}
            >
                <Box marginTop={{ base: '72px', md: '70px' }} fontSize={'24px'} fontWeight={'600'}>
                    <Text>Novo Orçamento</Text>
                </Box>
                
                <BudgetForm />
            </Flex>
        </Flex>
    );
}

export default RegisterBudget;