import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  Grid,
  Heading,
  Button,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Drawer from '../../components/Drawer';
import Header from '../../components/Header';
import { ToastContainer } from 'react-toastify';
import { ProductContext } from '../../contexts/Products';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/Auth';

function Home() {
  const url = 'https://esco.cognilabs.com.br/public/uploads/';
  const { listCategoriesApi } = useContext(ProductContext);
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState('');
  const [categories, setCategories] = useState();

  async function listCategories() {
    try {
      const categoriasApi = await listCategoriesApi();
      setCategories(categoriasApi.data[0]);
    } catch (error) {
      console.log(error);
    }
  }

  function goToCategory(categoryID) {
    localStorage.setItem('categoryID', categoryID); // Salva o categoryID no localStorage
  }

  useEffect(() => {
    if (user?.token) {
      listCategories();
    }
  }, [user?.token]);

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      // Exemplo de navegação para outra rota
      navigate('/search/produtos/' + searchInput);
    }
  }

  return (
    <>
      <Header />
      <Drawer />

      <Box
        mt="72px"
        mx={{ base: '16px', md: '72px', lg: '250px' }}
        ml={{ base: '100px', md: '' }}
        position={'absolute'}
        top={5}
        width={'fit-content'}
      >
        <Heading as="h1" fontSize="24px" color="#043455" mb="16px">
          O que você procura?
        </Heading>

        <InputGroup
          w={{ base: '100%', md: 'calc(100% - 180px)' }}
          borderRadius="10px"
          mb="24px"
        >
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            type="text"
            placeholder="Digite aqui o nome do Produto ou Aplicação"
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyPress}
            borderRadius="10px"
            bgColor="white"
            _focus={{ borderColor: '#BC080D', boxShadow: '0 0 0 1px #BC080D' }}
          />
        </InputGroup>
        <Box mb="72px">
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
            gap="32px"
          >
            {categories?.map((item) => (
              <Box
                key={item.title}
                h="190px"
                borderRadius="10px"
                bgColor="#C4C4C4"
                overflow="hidden"
                position="relative"
              >
                <Link
                  to={`/produtos/${item.title}`}
                  onClick={() => goToCategory(item.id)}
                >
                  <Box
                    w="500px"
                    h="100%"
                    bgImage={url + item.image}
                    bgSize="cover"
                    bgPosition="center"
                  >
                    <Heading
                      as="h3"
                      fontSize="40px"
                      fontWeight="600"
                      color="#FFF"
                      position="absolute"
                      bottom="30px"
                      left="30px"
                      maxW="60%"
                      lineHeight="40px"
                    >
                      {item.title}
                    </Heading>
                    <Button
                      position="absolute"
                      bottom="30px"
                      right="30px"
                      bgColor="#BC080D"
                      color="#FFF"
                      border="none"
                      borderRadius="4px"
                      padding="16px 24px"
                      fontSize="14px"
                      transition="all 0.3s"
                      _hover={{ bgColor: '#8c0509' }}
                    >
                      Ver Mais
                    </Button>
                  </Box>
                </Link>
              </Box>
            ))}
          </Grid>
        </Box>
      </Box>

      <ToastContainer position="top-right" autoClose={5000} pauseOnHover />
    </>
  );
}

export default Home;
