import React, { useContext } from "react";
import { Route, Routes, Navigate, createBrowserRouter } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import Product from "./pages/Products/Product";
import RegisterBudget from "./pages/Budgets/RegisterBudget";
import Budgets from "./pages/Budgets/Budgets";
import ListUsers from "./pages/Users/Users";
import CreateUser from "./pages/Users/CreateUser";
import Search from "./pages/Search/Search";
import NotFound from "./pages/NotFound/NotFound";
import ResetPassword from "./pages/Login/ResetPassword";
import { AppProvider } from "./contexts";

const Private = ({ children }) => {
  // const { user, authenticated } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  if (token === null) {
    return <Navigate to="/login" />;
  }

  return children;
};

const AppContext = ({ route }) => <AppProvider>{route}</AppProvider>;

// function Router() {
//   const Private = ({ children }) => {
//     // const { user, authenticated } = useContext(AuthContext);
//     const token = localStorage.getItem("token");
//     if (token === null) {
//       return <Navigate to="/login" />;
//     }

//     return children;
//   };

//   return (
//     <Routes>
//       <Route
//         exact
//         path="/"
//         element={
//           <Private>
//             <Home />
//           </Private>
//         }
//       />
//       <Route path="/login" element={<Login />} />
//       <Route path="/reset-password" element={<ResetPassword />} />

//       <Route
//         path="search/produtos/:search"
//         element={
//           <Private>
//             <Search />
//           </Private>
//         }
//       />

//       <Route
//         path="/produtos/:slug"
//         element={
//           <Private>
//             <Products />
//           </Private>
//         }
//       />

//       <Route
//         path="/produto/:id"
//         element={
//           <Private>
//             <Product />
//           </Private>
//         }
//       />

//       <Route
//         path="/registrar/orcamento"
//         element={
//           <Private>
//             <RegisterBudget />
//           </Private>
//         }
//       />

//       <Route
//         path="/orcamentos"
//         element={
//           <Private>
//             <Budgets />
//           </Private>
//         }
//       />

//       <Route
//         path="/usuarios"
//         element={
//           <Private>
//             <ListUsers />
//           </Private>
//         }
//       />

//       <Route
//         path="registrar/usuario"
//         element={
//           <Private>
//             <CreateUser />
//           </Private>
//         }
//       />

//       <Route path="*" element={<NotFound />} />
//     </Routes>
//   );
// }

// export default Router;

export const router = createBrowserRouter([
  {
    path: "/",

    element: (
      <AppContext
        route={
          <Private>
            <Home />
          </Private>
        }
      />
    ),
  },
  {
    path: "/login",
    element: <AppContext route={<Login />} />,
  },
  {
    path: "/reset-password",
    element: <AppContext route={<ResetPassword />} />,
  },
  {
    path: "search/produtos/:search",
    element: (
      <AppContext
        route={
          <Private>
            <Search />
          </Private>
        }
      />
    ),
  },
  {
    path: "/produtos/:slug",
    element: (
      <AppContext
        route={
          <Private>
            <Products />
          </Private>
        }
      />
    ),
  },
  {
    path: "/produto/:id",
    element: (
      <AppContext
        route={
          <Private>
            <Product />
          </Private>
        }
      />
    ),
  },
  {
    path: "/registrar/orcamento",
    element: (
      <AppContext
        route={
          <Private>
            <RegisterBudget />
          </Private>
        }
      />
    ),
  },
  {
    path: "/orcamentos",
    element: (
      <AppContext
        route={
          <Private>
            <Budgets />
          </Private>
        }
      />
    ),
  },
  {
    path: "/usuarios",
    element: (
      <AppContext
        route={
          <Private>
            <ListUsers />
          </Private>
        }
      />
    ),
  },
  {
    path: "registrar/usuario",
    element: (
      <AppContext
        route={
          <Private>
            <CreateUser />
          </Private>
        }
      />
    ),
  },
  {
    path: "*",
    element: (
      <AppContext
        route={
          <Navigate to="/" replace /> // Redirect to home for unmatched routes
        }
      />
    ),
  },
]);
