import AbstractCache from "./abstract-cache";
import IndexedDbCache from "./implementations/indexedDbCache";

const indexedDbCache = new AbstractCache(IndexedDbCache.getInstance());

export const saveOfflineRequest = async (domain, data) => {
    try {
        const { userId, token } = getUserInfo()
        if (!await indexedDbCache.get("tokens", userId)) await indexedDbCache.set("tokens", token, userId)
        data.userId = userId
        await indexedDbCache.set(domain, data)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export const getOfflineRequest = async (domain) => {
    try {
        const { userId } = getUserInfo()

        const request = await indexedDbCache.get(domain, userId)
        return request
    } catch (e) {
        console.log(e)
    }
}

const getUserInfo = () => {
    const userId = JSON.parse(localStorage.getItem('user')).id
    const token = localStorage.getItem('token')
    return { userId, token }

}