import { Box, Text } from '@chakra-ui/react';
import { useNetWorkContext } from '../contexts/Network';

import { motion } from 'framer-motion';

export const OfflineBanner = () => {
  const { isOnline } = useNetWorkContext();

  return (
    <motion.div layout>
      <Box
        width="100%"
        bg="#BC080D"
        color="white"
        padding={isOnline ? '0px' : '5px'}
        textAlign="center"
        height={isOnline ? '0px' : 'auto'}
      >
        <Text fontSize={{ sm: '12px', md: '16px' }}>
          Sem conexão, aplicativo em offline mode
        </Text>
      </Box>
    </motion.div>
  );
};
