import api from '../services/api';
import { createContext } from 'react';

export const ProductContext = createContext({});

export const ProductProvider = ({ children }) => {
  const listCategoriesApi = async () => {
    const response = await api.get('list/categories');

    return response;
  };

  const listProductsCategory = async (id) => {
    const response = await api.get(`list/products/category/${id}`);
    return response;
  };

  const listProductsSearch = async (search) => {
    const response = await api.get(`list/products/${search}`);
    return response;
  };

  const showProduct = async (id) => {
    const response = await api.get(`show/product/${id}`);
    return response;
  };

  return (
    <ProductContext.Provider
      value={{
        listCategoriesApi,
        listProductsCategory,
        showProduct,
        listProductsSearch,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
