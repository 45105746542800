import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Icon,
  Button,
  Image,
  Center,
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import Logo from "../../images/logo_esco.png";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../contexts/Auth";

const Login = () => {
  const { login, loading, forgotPasswordApi } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [emailForgot, setEmailForgot] = useState("");

  const handleSubmit = () => {
    login(email, password);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmitForgot = async () => {
    const data = {
      email: emailForgot,
    };

    try {
      const response = await forgotPasswordApi(data);
      toast.info("Email de recuperação enviado", {
        autoClose: 5000,
      });
      handleClose();
    } catch (error) {
      console.log(error);
      toast.info("Erro ao se conectar com o servidor!", {
        autoClose: 5000,
      });
    }
  };

  return (
    <>
      <Stack
        height={"100vh"}
        direction={{ base: "column", md: "row" }}
        backgroundColor={"#DB1015"}
      >
        <Flex
          flex={1}
          alt={"Login Image"}
          objectFit={"cover"}
          backgroundRepeat={"no-repeat"}
          justifyContent={"end"}
          alignItems={"center"}
        >
          <Box marginRight={12}>
            <Box maxWidth={{ base: "100%", md: "300px" }}>
              <Image src={Logo} maxWidth={"100%"} />
            </Box>
          </Box>
        </Flex>
        <Flex p={8} flex={1} align={"center"} justify={"start"}>
          <Stack spacing={2} w={"full"} maxW={"md"}>
            <Box backgroundColor={"white"} padding={12} width={"100%"}>
              <Text fontSize={22} fontWeight={800} pt={2} pb={2}>
                Faça seu login.
              </Text>

              <FormControl id="email" mt={5} mb={4}>
                <Input
                  fontSize={"14px"}
                  height={"44px"}
                  backgroundColor={"rgba(176,176,176,0.1)"}
                  placeholder={"Coloque seu email"}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>

              <FormControl id="password">
                <Input
                  fontSize={"14px"}
                  height={"44px"}
                  backgroundColor={"rgba(176,176,176,0.1)"}
                  placeholder={"Coloque sua senha"}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>

              <Stack spacing={3}>
                <Button
                  height={"50px"}
                  mt={5}
                  borderRadius={5}
                  bgColor={"#DB1015"}
                  type="submit"
                  onClick={handleSubmit}
                  colorScheme={"red"}
                  variant={"solid"}
                >
                  Sign in
                </Button>

                <Stack
                  align={"end"}
                  color={"#red"}
                  textDecoration={"underline"}
                  fontSize={"14px"}
                >
                  <Link onClick={handleOpen}>Esqueci a senha</Link>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Stack>

      {/* MODAL */}
      <Modal isOpen={isOpen} onClose={handleClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Esqueci a senha</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <FormControl>
                <FormLabel>E-mail</FormLabel>
                <Input
                  value={emailForgot}
                  type="email"
                  placeholder="Digite seu e-mail"
                  onChange={(event) => setEmailForgot(event.target.value)}
                />
              </FormControl>
              <Button onClick={handleSubmitForgot} mt={4} colorScheme="red">
                Enviar
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default Login;
