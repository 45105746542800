import { useState, useContext, useEffect } from 'react';

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductContext } from '../contexts/Products';
import { BudgetContext } from '../contexts/Budgets';
import { domains } from '../constants';
import { saveOfflineRequest } from '../services/cache';
import { NetworkContext } from '../contexts/Network';

export default function BudgetForm(props) {
  const { register, handleSubmit, formState, reset } = useForm();
  const { listCategoriesApi, listProductsCategory } =
    useContext(ProductContext);
  const { createBudgetApi } = useContext(BudgetContext);
  const [categories, setCategories] = useState();
  const [products, setProducts] = useState();

  const { isOnline, updateBudgetsOfflinee } = useContext(NetworkContext);

  async function listCategories() {
    try {
      const categoriasApi = await listCategoriesApi();
      setCategories(categoriasApi.data[0]);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleProducts(e) {
    try {
      const productsSelected = categories.find(
        (categorie) => categorie.id === Number(e)
      )?.products;

      setProducts(productsSelected || []);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    listCategories();
  }, []);

  const handleNetworkError = async (data) => {
    const saved = await saveOfflineRequest(domains.BUDGETS, data);
    if (!saved)
      return toast.error(
        'Ocorreu um erro ao enviar o orçamento. Por favor, tente novamente.'
      );

    toast.info(
      'Você parece estar offline. Seu orçamento será enviado assim que a conexão for restabelecida.'
    );
    updateBudgetsOfflinee(data);
    reset();
  };

  const onSubmit = async (data) => {
    if (isOnline) {
      try {
        const budget = await createBudgetApi(data);
        toast.success('Orçamento enviado com sucesso!');
        reset();
      } catch (error) {
        if (error.message === 'Network Error') {
          handleNetworkError(data);
          return;
        }
        // Exibir mensagem de erro
        toast.error(
          'Ocorreu um erro ao enviar o orçamento. Por favor, tente novamente.'
        );
      }
    } else {
      handleNetworkError(data);
    }
  };

  return (
    <>
      <VStack
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        id={props?.id}
        spacing={8}
        align="stretch"
        py={12}
        reset={reset}
        width={'75vw'}
      >
        <FormControl isRequired>
          <FormLabel>Nome</FormLabel>
          <Input
            type="text"
            name="nome"
            defaultValue=""
            {...register('name', { required: true })}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            defaultValue=""
            {...register('email', { required: true })}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Telefone</FormLabel>
          <Input
            type="tel"
            name="telefone"
            defaultValue=""
            {...register('phone', { required: true })}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Família do Produto</FormLabel>
          <Select
            onChange={(event) => handleProducts(event.target.value)}
            //name="familia"
            // {...register("familia", { required: true })}
          >
            <option value="">Família do Produto</option>
            {categories?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Produto</FormLabel>
          <Select
            name="produto"
            defaultValue=""
            {...register('product_id', { required: true })}
          >
            <option value="">
              {!products || products?.length === 0
                ? 'Selecione família do produto'
                : 'produto'}
            </option>
            {products?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Dê mais detalhes sobre o seu orçamento</FormLabel>
          <Textarea
            name="details"
            defaultValue=""
            {...register('details')}
            rows={6}
          />
        </FormControl>

        <Button
          type="submit"
          colorScheme="red"
          isLoading={formState.isSubmitting}
          loadingText="Enviando"
        >
          Solicitar Orçamento
        </Button>
      </VStack>

      <ToastContainer />
    </>
  );
}
