import React from "react";
import {
    Box,
    Flex,
    Text,
} from '@chakra-ui/react';
import Drawer from '../../components/Drawer';
import Header from '../../components/Header';
import NewUserForm from "../../components/NewUserForm";

function CreateUser() {

    return (
        <>
        
        <Drawer />
            <Header />
            
            <Flex
                minWidth={'100%'}
                marginTop={{ base: '72px', md: '50px' }}
                ml={{ base: '100px', md: '150px' }}
                flexDir={'column'}
            >
                <Box marginTop={{ base: '72px', md: '70px' }} fontSize={'24px'} fontWeight={'600'}>
                    <Text>            
                        Cadastro De Usuário
                   </Text>
                </Box>

                <NewUserForm />

            
            </Flex>
        </>
    );
}

export default CreateUser;
