import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Legal from "../images/legal.svg";

const Success = (props) => {
    return (
        <Flex
            w="100%"
            h="100%"
            borderRadius="50%"
            bgColor="#DB1015"
            color="#FFF"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            fontSize="1em"
        >
            <Image src={Legal} alt=" " w="40%" />
            <Text textAlign="center" fontWeight="bold" fontSize="1em">
                Case de Sucesso
            </Text>
        </Flex>
    );
};

export default Success;