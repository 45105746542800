import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import { domains } from '../constants';
import AbstractCache from '../services/cache/abstract-cache';
import IndexedDbCache from '../services/cache/implementations/indexedDbCache';
import { toast } from 'react-toastify';

export const NetworkContext = createContext({});

// let listenerStarted = false;
const indexedDbCache = new AbstractCache(IndexedDbCache.getInstance());

export const NetworkProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [budgetsOffline, setBudgetsOffline] = useState([]);
  const timeToRetry = 30 * 1000;
  const maxAttempts = 3;

  const onlineListenerRef = useRef(null);
  const offlineListenerRef = useRef(null);

  const api = axios.create({
    baseURL: 'https://esco.cognilabs.com.br/api/v1',
  });

  const handleOnline = () => {
    setIsOnline(true);
    syncWithServer();
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  const updateBudgetsOfflinee = useCallback(
    (data) => {
      setBudgetsOffline((prev) => [...prev, data]);
    },
    [isOnline]
  );

  const syncWithServer = useCallback(
    async (attempt = 1) => {
      const tokens = {};
      let success = 0;
      let error = 0;

      //Caso haja mais domínios, o método deve ser refatorado para receber uma lista de domínios

      const budgetsCache = await indexedDbCache.getAll(domains.BUDGETS);
      if (budgetsCache.length === 0) return;
      toast.info('Sincronizando dados com o servidor');
      const total = budgetsCache.length;

      const budgets = {};

      //Separa as solicitações por usuário
      for (const budget of budgetsCache) {
        if (!tokens[budget.userId]) {
          tokens[budget.userId] = await indexedDbCache.get(
            domains.TOKENS,
            budget.userId
          );
        }

        if (!budgets[budget.userId]) budgets[budget.userId] = [];
        budgets[budget.userId].push(budget);
      }

      //Processa as solicitações por usuário

      for (const userId in budgets) {
        const userBudgets = budgets[userId];
        try {
          const response = await api.post(
            'synchronize',
            { budgets: userBudgets },
            { headers: { Authorization: `Bearer ${tokens[userId]}` } }
          );

          await removeBudgetsFromCache(userBudgets);
          success += userBudgets.length;
        } catch (e) {
          console.error(e);
          error += userBudgets.length;
        }
      }
      handleFinish(success, error, total, attempt);
    },
    [isOnline, budgetsOffline]
  );

  const removeBudgetsFromCache = async (budgets) => {
    for (const budget of budgets) {
      await indexedDbCache.delete(domains.BUDGETS, budget.id);
    }
  };

  const handleFinish = (success, error, total, attempt) => {
    if (success + error !== total) return;

    if (success === total) {
      toast.success('Sincronização finalizada com sucesso');
      setBudgetsOffline([]);
      return;
    }

    const maxAttemptsReached = attempt === maxAttempts;
    const message = maxAttemptsReached
      ? 'Número máximo de tentativas atingido. Entre em contato com o suporte técnico'
      : 'Dentro de alguns instantes, tentaremos novamente.';
    if (error === total)
      toast.error(`Erro ao sincronizar dados com o servidor. ${message}`);
    else
      toast.warning(
        `Alguns dados não foram sincronizados com o servidor. ${message}`
      );

    if (!maxAttemptsReached) {
      setTimeout(() => {
        syncWithServer(attempt + 1);
      }, timeToRetry);
    }
  };
  // if (!listenerStarted) {
  //   console.log('Starting listeners');
  //   listenerStarted = true;
  //   window.removeEventListener('online', handleOnline);
  //   window.removeEventListener('offline', handleOffline);
  //   window.addEventListener('online', handleOnline);
  //   window.addEventListener('offline', handleOffline);
  // }

  // o useEffect é disparado 2x devido ao strictMode do react. (somente em debug pode ignorar ou então remover o stric mode)

  useEffect(() => {
    (async () => {
      const budgetsCache = await indexedDbCache.getAll(domains.BUDGETS);
      if (budgetsCache?.length >= 0) {
        setBudgetsOffline(budgetsCache);
      }
    })();
  }, [isOnline]);

  useEffect(() => {
    if (!onlineListenerRef.current) {
      onlineListenerRef.current = window.addEventListener(
        'online',
        handleOnline
      );
    }
    if (!offlineListenerRef.current) {
      offlineListenerRef.current = window.addEventListener(
        'offline',
        handleOffline
      );
    }

    return () => {
      if (onlineListenerRef.current) {
        window.removeEventListener('online', handleOnline);
      }
      if (offlineListenerRef.current) {
        window.removeEventListener('offline', handleOffline);
      }
    };
  }, []);

  return (
    <NetworkContext.Provider
      value={{
        isOnline,
        budgetsOffline,
        syncWithServer,
        updateBudgetsOfflinee,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};

export const useNetWorkContext = () => useContext(NetworkContext);
