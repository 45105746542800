class IndexedDbCache {
    static _instance = null;
    _indexDbConfig = {
        dbName: 'escoDB',
    }
    _db = null

    static getInstance() {
        if (!this._instance) {
            this._instance = new IndexedDbCache();
        }
        return this._instance;
    }


    _openDB(key, autoIncrement) {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this._indexDbConfig.dbName);
            request.onsuccess = (event) => {
                const db = event.target.result;
                const version = +db.version || 1;
                const hasKey = Object.values(db.objectStoreNames).includes(key)
                if (hasKey) {
                    this._db = db
                    return resolve()
                }
                db.close();
                const request2 = indexedDB.open(this._indexDbConfig.dbName, version + 1);
                request2.onupgradeneeded = (event) => {
                    const db = event.target.result;
                    db.createObjectStore(key, { autoIncrement });
                }
                request2.onsuccess = (event) => {
                    const db = event.target.result;
                    this._db = db
                    resolve();
                }

            };
        });
    }

    async _checkConnection(key, autoIncrement = true) {
        if (this._db === null) await this._openDB(key, autoIncrement)
    }

    _closeDB() {
        if (this._db) {
            this._db.close();
            this._db = null;
        }
    }

    async set(key, value, id) {
        return new Promise(async (resolve, reject) => {
            const autoIncrement = id === null;
            await this._checkConnection(key, autoIncrement);
            const transaction = this._db.transaction([key], 'readwrite');
            const store = transaction.objectStore(key);
            const request = id !== null ? store.add(value, id) : store.add(value);

            request.onsuccess = (event) => {
                this._closeDB();
                const returnedId = event.target.result
                resolve(returnedId);
            };

            request.onerror = (event) => {
                this._closeDB();
                reject('Data write failed: ' + event.target.errorCode + ` | ${key} -> ${id ? id + ': ' : ''} ${value}`);
            };
        });
    }

    get(key, id) {
        return new Promise(async (resolve, reject) => {
            await this._checkConnection(key);
            const transaction = this._db.transaction([key], 'readonly');
            const store = transaction.objectStore(key);
            const request = store.get(id);
            request.onsuccess = (event) => {
                this._closeDB();
                resolve(event.target.result);
            };

            request.onerror = (event) => {
                this._closeDB();
                reject('Data fetch failed: ' + event.target.errorCode);
            };
        });
    }

    getAll(key) {
        return new Promise(async (resolve, reject) => {
            await this._checkConnection(key);
            const transaction = this._db.transaction([key], 'readonly');
            const store = transaction.objectStore(key);
            const request = store.getAllKeys();

            request.onsuccess = async (event) => {
                this._closeDB();
                const ids = event.target.result
                const values = await Promise.all(ids.map(async id => {
                    const value = await this.get(key, id)
                    return { id, ...value }
                }))
                resolve(values);
            };

            request.onerror = (event) => {
                this._closeDB();
                reject('Data fetch failed: ' + event.target.errorCode);
            };
        });
    }

    delete(key, id) {
        return new Promise(async (resolve, reject) => {
            await this._checkConnection(key);
            const transaction = this._db.transaction([key], 'readwrite');
            const store = transaction.objectStore(key);
            
            const request = store.delete(id);

            request.onsuccess = (event) => {
                this._closeDB();
                resolve();
            };

            request.onerror = (event) => {
                this._closeDB();
                reject('Data delete failed: ' + event.target.errorCode);
            };
        });
    }
    clearAll() {
        this._cacheImplementation.clearAll()
    }
}

export default IndexedDbCache;