import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Flex, Image, Button, IconButton, Box } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import LogoAzul from '../images/logo_azul.png';
import { OfflineBanner } from './OfflineBanner';

const Header = (props) => {
  //const history = useHistory()

  return (
    <Box
      position="fixed"
      right={0}
      top={0}
      width="calc(100% - 72px)"
      background="#FFF"
      borderBottom="1px solid #C4C4C4"
      zIndex={997}
    >
      <OfflineBanner />
      <Flex
        alignItems="center"
        padding="15px 20px"
        justifyContent="space-between"
      >
        <Link to="/">
          <IconButton
            aria-label="Voltar"
            icon={<ArrowBackIcon />}
            colorScheme="red"
            variant="ghost"
            fontSize="18px"
            borderRadius="4px"
            mr={2}
          />
        </Link>
        <Link to="/">
          <Image src={LogoAzul} alt="" h="20px" />
        </Link>
      </Flex>
    </Box>
  );
};

export default Header;
