import {
    useState, useContext, useEffect
} from "react";
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Table,
    TableCaption,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import Drawer from '../../components/Drawer';
import Header from '../../components/Header';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from "../../contexts/User";

const ListUsers = () => {
    const [rows, setRows] = useState([]);

    const { listUsersApi, activateUserApi, inactivateUserApi } = useContext(UserContext);

    async function listUsers() {
        try {
            const usersApi = await listUsersApi();
            setRows(usersApi.data[0]);
            console.log(usersApi.data[0]);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        listUsers()
    }, [])

    const handleActive = async (e, id) => {
        try {
            const userAct = await activateUserApi(id);
            console.log(userAct);
            // Exibir mensagem de sucesso
            toast.success('Usuário Ativado com sucesso!');
            listUsers();
        } catch (error) {
            console.log(error);

            // Exibir mensagem de erro
            toast.error('Ocorreu um erro ao ativar p o usuário. Por favor, tente novamente.');
        } 
    };

    const handleInactivate = async (e, id) => {
        try {
            const userAct = await inactivateUserApi(id);
            console.log(userAct);

            // Exibir mensagem de sucesso
            toast.success('Usuário inativado com sucesso!');
            listUsers()
        } catch (error) {
            console.log(error);

            // Exibir mensagem de erro
            toast.error('Ocorreu um erro ao inativar o usuário. Por favor, tente novamente.');
        } 
    };


    return (
        
        <>
            <Drawer />
            <Header />
            <Flex>

                <Box w="100%">

                    <Container
                        minWidth={'75vw'}
                        marginTop={{ base: '72px', md: '120px' }}
                        ml={{ base: '100px', md: '150px' }}>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Heading as="h1" color="blue.700" fontSize={{ base: "20px", md: "24px" }}>
                                Usuários
                            </Heading>
                            <Button
                                leftIcon={<AddIcon />}
                                bg="red.500"
                                color="white"
                                _hover={{ bg: "red.600" }}
                                size="sm"

                                fontSize={{ base: "12px", md: "14px" }}
                                px={{ base: "10px", md: "16px" }}
                                py={{ base: "6px", md: "8px" }}
                                mt={{ base: "16px", md: '16px' }}
                            >
                                Novo Usuário
                            </Button>
                        </Flex>
                        <Table variant="striped" colorScheme="gray" mt={'45px'}>
                            <TableCaption>
                                Lista de usuários cadastrados
                            </TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>Nome</Th>
                                    <Th>Data de Nascimento</Th>
                                    <Th>Telefone</Th>
                                    <Th>Tipo de Usuário</Th>
                                    <Th>Status</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {rows.length > 0 &&
                                    rows.map((row) => (
                                        <Tr key={row.id}>
                                            <Td>{row.name}</Td>
                                            <Td>{row.birthdate}</Td>
                                            <Td>{row.phone}</Td>
                                            <Td>
                                                <Text textTransform="capitalize">{row.role_id == 1 ? 'Administrador' : 'Consultor'}</Text>
                                            </Td>
                                            <Td>
                                                <Button
                                                    size="sm"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                    borderRadius="6px"
                                                    colorScheme={row.status === 1 ? "green" : "red"}
                                                    onClick={(e) => handleActive(e, row.id)}
                                                    _hover={{ bg: row.status === 1 ? "green.600" : "red.600" }}
                                                >
                                                    {row.status === 1 ? "ativo" : "inativo"}
                                                </Button>
                                            </Td>
                                            <Td>
                                                <Button
                                                    size="sm"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                    borderRadius="6px"
                                                    colorScheme={row.status === 2 ? "green" : "red"}
                                                    onClick={row.status === 1 ? (e) => handleInactivate(e, row.id) : (e) => handleActive(e, row.id) }
                                                    _hover={{ bg: row.status === 1 ? "green.600" : "red.600" }}
                                                >
                                                    {row.status === 1 ? "Inativar" : "Ativar"}
                                                </Button>
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        </Table>
                        <ToastContainer />
                    </Container>
                </Box>
            </Flex>
        </>
    )
};

export default ListUsers;
