import api from '../services/api';
import { createContext, useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import IndexedDbCache from '../services/cache/implementations/indexedDbCache';
import AbstractCache from '../services/cache/abstract-cache';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const indexedDbCache = new AbstractCache(IndexedDbCache.getInstance());
  const toast = useToast();
  const [user, setUser] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const recoveredUser = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    if (recoveredUser && token) {
      setUser({ ...JSON.parse(recoveredUser), token: token });
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await api.post('auth/login', { email, password });

      if (response.status === 200) {
        const loggedUser = response.data.user;
        const token = response.data.token;
        api.defaults.headers.Authorization = `Bearer ${token}`;
        localStorage.setItem('user', JSON.stringify(loggedUser));
        localStorage.setItem('token', token);

        setUser({ ...loggedUser, token });
        // window.location.pathname = "/home"
        await indexedDbCache.delete("tokens", loggedUser.id)
        await indexedDbCache.set("tokens", token, loggedUser.id)

        navigate('/');

        toast({
          title: 'Usuário Logado!',
          description: 'Aproveite nosso sistema.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        // pra que isso ta aqui?
        // if (token) {
        //   window.onload = function () {
        //     authenticated = true;
        //   };
        // }
      }
      return response;
    } catch (error) {
      JSON.stringify(error);
      console.log(error);
      toast({
        title: 'Deu algo errado',
        status: 'error',
        isClosable: true,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const logout = () => {
    console.log('logout');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    api.defaults.headers.Authorization = null;
    setUser(null);
    navigate('/login');
  };

  const forgotPasswordApi = async (data) => {
    const token = localStorage.getItem('token');
    const response = await api.post('forgot/password', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    return response;
  };

  const resetPasswordApi = async (data) => {
    const token = localStorage.getItem('token');
    const response = await api.post('reset/password', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    return response;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: !!user,
        login,
        logout,
        forgotPasswordApi,
        resetPasswordApi,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
