class AbstractCache {
    _cacheImplementation = null;
    constructor(cacheImplementation) {
        this._cacheImplementation = cacheImplementation
    }
    async set(key, value, id = null) {
        return await this._cacheImplementation.set(key, value, id);
    }
    async get(key, id) {
        return await this._cacheImplementation.get(key, id);
    }
    async getAll(key) {
        return await this._cacheImplementation.getAll(key);
    }
    async delete(key, id){
        await this._cacheImplementation.delete(key, id)
    }
    async clearAll() {
        await this._cacheImplementation.clearAll()
    }
}

export default AbstractCache;