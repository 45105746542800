import axios from 'axios';
import { router } from '../routes';

const api = axios.create({
  // baseURL: "http://localhost/api-esco/public/api/"
  // baseURL: "https://api-esco.prowork.digital/public/api/"
  baseURL: 'https://esco.cognilabs.com.br/api/v1',
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');

  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['Access-Control-Allow-Origin'] = '*';
  return config;
});

api.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    console.log(error);

    if (error?.response?.status === 401) {
      // window.location.pathname = "/";
      console.log('no logado');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      api.defaults.headers.Authorization = null;
      router.navigate('/login');

      // if ((window.location.pathname = "/" && error?.response?.status === 401)) {
      //   // window.location.pathname = "/login";
      //   router.navigate("/login");
      // }
    }

    return Promise.reject(error);
  }
);

export default api;
